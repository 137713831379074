import { useState, useEffect } from "react";
// components
import Avatar from "./Avatar";

// styles
import "./OnlineUsers.css";

export default function OnlineUsers() {

  const [onlineCharacters, setOnlineCharacters] = useState([]);

  async function getOnlineCharacters() {

    const response = await fetch(`https://www.mapplestrudel.com/character/onlineList`, { method: "GET" });
    const charObj = await response.json();

    console.log(charObj.charactersNames);

    setOnlineCharacters(charObj.charactersNames);

  }

  useEffect(() => {
    getOnlineCharacters();
  }, []);

  return (
    <div className="user-list">
      <h2>All Users</h2>
        {onlineCharacters?.map(character => (
          <div className="user-list-item">
            <span className="online-user"></span>
            <span>{character}</span>
            <Avatar name={character}/>
          </div>
         ))}
    </div>
  );
}
