// styles & images
import "./Navbar.css";
import Logo from "../assets/mushroom.png";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { menu } from "react-icons-kit/feather/menu";
import { x } from "react-icons-kit/feather/x";

export const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const closeMenu = () => {
    setToggle(false);
  };

  return (
    <nav className={toggle ? "navbar expanded" : "navbar"}>
      <h2 className="logo">
        <img src={Logo} alt="logo" />
        <span>MappleStrudle</span>
      </h2>
      <div className="toggle-icon" onClick={handleToggle}>
        {toggle ? <Icon icon={x} size={28} /> : <Icon icon={menu} size={28} />}
      </div>
      <ul className="links">
        <li>
          <Link to="/" onClick={closeMenu}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/download" onClick={closeMenu}>
            Download
          </Link>
        </li>
        <li>
          <Link to="/user-management" onClick={closeMenu}>
            User Management
          </Link>
        </li>
      </ul>
      <div className="right">
        <Link to="/login">
          <button className="btn" onClick={closeMenu}>
            Login
          </button>
        </Link>
        <Link to="/signup">
          <button className="btn-secondary" onClick={closeMenu}>
            Signup
          </button>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
