// styles
//import { useState, useEffect } from 'react';
import "./Avatar.css";
//import { Buffer } from 'buffer';
//import P from "../assets/p.png";



export default function Avatar(props) {
  const userName = props.name;

  return (
    <div className="avatar">
      <img src={`./characterPng/${userName}.png`} alt="user avatar" />
    </div>
  );
}
