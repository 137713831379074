import { useForm } from "react-hook-form";
import "./Login.css";

const Login = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    try {
        await fetch("https://www.mapplestrudel.com/account/login", {method: 'POST', body: data});
    } catch (err) {
        alert("There was a problem with your log-in")
        console.log("the error is - " + err);
    }
  };
  return (
    <div className="login">
      <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
        <h2>Login</h2>
        <label>
          <span>User Name:</span>
          <input required type="text" placeholder="username" {...register("name", { required: true })} />
        </label>
        <label>
          <span>password:</span>
          <input required type="password" {...register("password", { required: true })} />
        </label>
        <input type="submit" value="Log In" className='btn' id="login-button" />
        <p>Having problems connecting? mail your service administrator</p>
        {/* <div className="error">error message</div> */}
      </form>
    </div>
  );
};

export default Login;
