import "./Home.css";
import homeImg from "../assets/home-img.png";

const Home = () => {
  return (
    <div className="home">
      <h1>Welcome to MappleStrudle</h1>
      <h2>Who Are We?</h2>
      <p>
        Welcome to MappleStrudel, where a passionate team of game enthusiasts is reshaping the MapleStory experience! 
        We are not just developers; we are avid fans of the original game, driven by a deep love and nostalgia that has fueled our desire to create something truly unique.
      </p>
      <h2>Our Mission</h2>
      <p>
        At the core of our mission is a commitment to blending the cherished memories of MapleStory with a modernized and sophisticated game design philosophy. 
        We understand the pulse of the MapleStory community and aim to redefine the classic by offering an exhilarating co-op roguelike adventure.
      </p>
      <h2>A Nostalgic Journey with a Twist</h2>
      <p>
        Imagine a MapleStory game where classes are a thing of the past, monsters don't respawn, and every run is a fresh, unpredictable experience. 
        We've taken the essence of the original game and turned it on its head to deliver a gameplay experience that is both familiar and excitingly new.
      </p>
      <h2>Embracing Randomization</h2>
      <p>
        Skills, portals, and more - we embrace the power of randomization to keep each playthrough dynamic. 
        No two adventures are the same, providing a thrilling sense of unpredictability and challenge that keeps you on the edge of your seat.
      </p>
      <h2>Why MappleStrudel?</h2>
      <h3>Passion</h3>
      <p>
        We're not just developers; we're MapleStory enthusiasts crafting an experience we want to play ourselves.
      </p>
      <h3>Innovation</h3>
      <p>
        We're breaking away from traditional norms to offer a co-op roguelike that redefines the MapleStory universe.
      </p>
      <h3>Open Source</h3>
      <p>
        Server side code is completely open source! Join us on this nostalgic journey and become a part of a community that shares the same love for MapleStory's legacy.
      </p>
      <h4>
        Discover MapleStory in a way you've never imagined with MappleStrudel. Let the nostalgia guide you, and the excitement propel you into a new era of gaming!
      </h4>
      <img className="home-img" src={homeImg} alt="home-img" />
    </div>
  );
};

export default Home;
