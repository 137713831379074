import React from "react";
//import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const Signup = () => {
  //const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    try {
        await fetch("https://www.mapplestrudel.com/account/register", {method: 'POST', body: data});
    } catch (err) {
        alert("There was a problem with your register")
        console.log("the error is - " + err);
    }
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
      <h2>sign up</h2>
      <label>
        <span>User Name:</span>
        <input required type="text" {...register("name", { required: true })} name="name" placeholder="mario@mail.com" maxlength="13" pattern="[a-zA-Z0-9]+" />
        <span>email:</span>
        <input required type="email" placeholder="mario@mail.com" {...register("email", { required: true })} />
      </label>
      <label>
        <span>password:</span>
        <input required type="password" {...register("password", { required: true })} />
      </label>
      <input type="submit" value="Sign In" className='btn' id="signup-button"/>
      {/* <div className="error">error message</div> */}
    </form>
  );
};

export default Signup;
