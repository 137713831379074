import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Navbar from "./components/Navbar";
import Download from "./pages/Download";
import Home from "./pages/Home";
import OnlineUsers from "./components/OnlineUsers";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import UserManagement from "./pages/UserManagement";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="container">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/download" element={<Download />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/user-management" element={<UserManagement />} />
          </Routes>
        </div>
        <OnlineUsers />
      </BrowserRouter>
    </div>
  );
}

export default App;
